<script>
  let string1 = `<strong>SGS Classes</strong> is an initiative of HH Sri Ganapathy Sachchidananda Swamiji.`;

  let string2 = `<strong>SriSwamiji's</strong> divine sankalpa is to use <i style="color:MediumSeaGreen;"><strong>SGS Classes</strong></i> as a tool to spread knowledge
      and awareness of tradition (Sanskriti), Deva Bhasha (Sanskrit) and Guru
      Bhasha (Kannada).`;

  let string3 =
    '<a href = "mailto:sgsclasses9@gmail.com?subject = Query on SGS Classes&body = Message">Send your queries or feedback <strong>here.</strong></a>';
</script>

<style>
  /* Reflow Styling */
  main[role="main"] {
    grid-area: main;
  }

  .grid,
  .subgrid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
  }

  .grid {
    grid-template-areas:
      "header"
      "main"
      "aside"
      "footer";
    width: 100%;
  }

  .subgrid {
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  }

  .grid-item,
  .subgrid-item {
    padding: 1rem;
  }

  @media all and (min-width: 576px) {
    .subgrid {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      margin-bottom: 1rem;
    }
    .subgrid-item {
      padding-bottom: 0.25rem;
    }
  }

  @media all and (min-width: 992px) {
    .grid {
      grid-template-areas:
        "header header header"
        "main main aside"
        "footer footer footer";
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }
  }
</style>

<div>
  <body class="grid">

    <main role="main" class="grid-item">
      <div class="subgrid">
        <div class="subgrid-item">
          <img src="/assets/images/banner.jpg" alt="SGS classes" />
        </div>
        <div class="subgrid-item">
          <p>
            {@html string1}
          </p>
          <p>
            {@html string2}
          </p>
          <p>
            {@html string3}
          </p>
        </div>
        <div class="subgrid-item">
          <p>
            To know more about activities of Avadhoota Datta Peetham, visit
            <strong>
              <a href="http://www.dattapeetham.org">website here</a>
            </strong>
            or visit Datta Peetham facebook page
            <strong>
              <a href="https://www.facebook.com/sgspage">here.</a>
            </strong>
          </p>
        </div>
      </div>
    </main>

  </body>
</div>
