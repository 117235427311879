<script>
  export let id;
  //export let location;
  import { addToCart } from "../stores/cart";
  import products from "../stores/products";
  import Loading from "../components/Loading.svelte";
  import { link } from "svelte-routing";
  import globalStore from "../stores/globalStore";
  import user from "../stores/user";
  $: product = $products.find((item) => item.id === parseInt(id));
</script>

<svelte:head>
  <title>{!product ? 'single product' : product.title}</title>
</svelte:head>

{#if !product}
  <Loading />
{:else}
  <section class="single-product">
    <!-- back to products -->
    <a href="/products" use:link class="btn btn-primary">back to class list</a>
    <!-- single product container -->
    <div class="single-product-container">
      <article class="single-product-image">
        <img src={product.course_image} alt={product.title} />
      </article>
      <article>
        <h1>{product.course_title}</h1>
        <h2>{product.paid_or_free} online course</h2>
        <p>
          {@html product.course_description}
        </p>
        <!--   {#if $user.username} -->
        <a href="/registerForCourse/{id}" use:link class="btn btn-primary">
          Go to registration form
        </a>
        <!--     {:else}
          <button
            class="btn btn-primary btn-block"
            on:click={() => {
              globalStore.toggleItem('cart', true);
            }}>
            Interested?
          </button>
        {/if} -->

      </article>
    </div>
  </section>
{/if}
