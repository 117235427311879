<script>
  import CartButton from "../Cart/CartButton.svelte";
  import { link } from "svelte-routing";
  import links from "../../constants/links";
  import LoginLink from "../LoginLink.svelte";
</script>

<nav class="navbar">
  <div class="nav-container">
    <div class="nav-center">
      <!-- nav links -->
      <ul class="nav-links">
        {#each links as navLink}
          <li>
            <a href={navLink.url} use:link>{navLink.text}</a>
          </li>
        {/each}
      </ul>
      <!-- nav logo -->
      <a href="/" use:link class="nav-logo big-logo">
        <!--    <img src="/assets/images/sgslogo.jpg" class="logo" alt="SGSlogo" /> -->

        <strong class="sgstitle">SGS Classes</strong>

      </a>
      <!-- cart toggle -->
      <!--Commenting out for now
      <div class="nav-aside">
        <LoginLink />

        <CartButton />
      </div> -->

    </div>
  </div>
</nav>
