import axios from "axios";
import url from "./URL";
async function registerForClass({ full_name, email, phone, id, country, state, medium_of_instruction, timing_preference }) {
  //console.log(
  //  "Values received for registering",
  //  full_name,

  //  phone
  //);

  const response = await axios
    .post(
     // `${url}/courses/registerinterest`,
        `${url}/register_course/`,
      {
        user_name: full_name,
        user_email: email,
        user_phone: phone,
        course_id: parseInt(id),
          user_country: country,
          user_state: state,
          medium_of_instruction: medium_of_instruction,
          timing_preference: timing_preference
      }
      /*   {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }*/
    )
    .catch((error) => console.log(error));

  return response;
}

export default registerForClass;
