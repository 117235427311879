<script>
  export let product;
  const { course_title, course_image, paid_or_free, id } = product;
  import { link } from "svelte-routing";
</script>

<article class="product">
  <div class="img-container">
    <img src={course_image} alt={course_title} />
    <a href={`/products/${id}`} class="btn btn-primary product-link" use:link>
      details
    </a>
  </div>
  <div class="product-footer">
    <p class="product-title">{course_title}</p>
    <p class="product-price">{paid_or_free} online course</p>
  </div>
</article>
