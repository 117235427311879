<script>
  export let id;
  import loginUser from "../strapi/loginUser";
  import registerUser from "../strapi/registerUser";
  import registerForClass from "../strapi/registerForClass";
  import { navigate } from "svelte-routing";
  import globalStore from "../stores/globalStore";
  import user from "../stores/user";
  let full_name = "";
  let date_of_birth = "";
  let gender = "";
  let phone = "";
  let address = "";
  let gothram = "";
  let email = "";
  let city = "";
  let country = "";
  let state = "";
  let medium_of_instruction = "";
  let timing_preference = "";
  let answer = "";
  let isMember = true;
  let courses_interested = "";
  import products from "../stores/products";
  let userToken = $user.jwt;
  // add alert

  $: isEmpty = !full_name || !phone || !email || !country || !state || !medium_of_instruction || !timing_preference || $globalStore.alert;
  $: product = $products.find((item) => item.id === parseInt(id));

  // handle submit
  async function handleSubmit() {
    // add alert
    globalStore.toggleItem("alert", true, "loading data... please wait!");
    let user;
    user = await registerForClass({
      full_name,
      email,
      phone,
      id,
      country,
      state,
      medium_of_instruction,
      timing_preference,
    });
    //console.log("Ret value is", user);
    if (user) {
      navigate("/products");
      globalStore.toggleItem(
        "alert",
        true,
        "Thank you for the registration. You will be contacted for the next available class!"
      );
      // add alert
      return;
    }
    // add alert
    globalStore.toggleItem(
      "alert",
      true,
      "there was an error! please try again",
      true
    );
  }
</script>

<section class="form">
  <h2 class="section-title">SGS Classes</h2>
  <h2>Class registration form for {product.course_title}</h2>
  <form class="login-form" on:submit|preventDefault={handleSubmit}>
    <!-- single input -->
    <div class="form-control">
      <label for="full_name">Full name</label>
      <input type="text" id="full_name" bind:value={full_name} />
    </div>
    <!--end of  single input -->
    <!-- single input -->
    <div class="form-control">
      <label for="email">email</label>
      <input type="email" id="email" bind:value={email} />
    </div>
    <!-- single input -->
    <div class="form-control">
      <label for="phone">Mobile phone</label>
      <input type="text" id="phone" bind:value={phone} />
    </div>
    <!--end of  single input -->
    <!-- single input -->
    <div class="form-control">
      <label for="country">Country</label>
      <input type="text" id="country" bind:value={country} />
    </div>
    <!--end of  single input -->
    <!-- single input -->
    <div class="form-control">
      <label for="state">State</label>
      <input type="text" id="state" bind:value={state} />
    </div>
    <!--end of  single input -->
    <!-- single input -->
    <div class="form-control">
      <label for="medium_of_instruction">Medium of instruction (Telugu/Kannada/English)</label>
      <input type="text" id="medium_of_instruction" bind:value={medium_of_instruction} />
    </div>
    <!--end of  single input -->
    <!-- single input -->
    <div class="form-control">
      <label for="timing_preference">Timing preference (select from available slots below)</label>
      <label style="color:blueviolet;font-size:15px;">Sanskrit: 6-7 AM, 5-6 PM IST</label>
      <label style="color:blueviolet;font-size:15px;">Telugu, Kannada: 6-7 PM, 9-10 PM IST</label>
      <label style="color:blueviolet;font-size:15px;">Puja Vidhi: 9-10 PM IST</label>
      <input type="text" id="timing_preference" bind:value={timing_preference} />
    </div>
    <!--end of  single input -->
    {#if isEmpty}
      <p class="form-empty">please fill out all form fields.</p>
    {/if}
    <button
      type="submit"
      class="btn btn-block btn-primary"
      disabled={isEmpty}
      class:disabled={isEmpty}>
      submit
    </button>

  </form>

</section>
